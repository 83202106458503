.poper-section {
    width: 200px;
    margin: 15px 6px;

    &__chips {
        color: var(--color-white) !important;
        margin: 5px !important;
        font-size: var(--font-size-10) !important;

        &:hover {
            background-color: var(--color-secondry) !important;
        }
    }

    &__chips.bg-secondry {
        background-color: var(--color-secondry) !important;
    }

    &__popper-btn {
        font-weight: 500 !important;
        font-size: 0.875rem !important;
        line-height: 1.5 !important;
        padding: 8px 16px !important;
        border-radius: 8px !important;
        color: var(--color-white) !important;
        -webkit-transition: all 150ms ease !important;
        transition: all 150ms ease !important;
        cursor: pointer !important;
        background: transparent !important;
        border: 1px solid var(--color-light-blue) !important;
        color: var(--color-white) !important;
        box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05) !important;
        width: 100% !important;
    }

    &__popper-btn.filled-bg {
        background: var(--color-light-blue) !important;
        font-weight: 600 !important;
    }

    &__chips-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-top: 6px;
    }
}

.css-1r3mz6a-MuiButtonBase-root-MuiChip-root {
    font-family: 'Poppins', sans-serif !important;
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .poper-section {
        width: 140px;

        &__chips {
            font-size: 9px !important;
        }

        &__popper-btn {
            font-weight: 500 !important;
            font-size: 0.675rem !important;
            line-height: 1.5 !important;
            padding: 8px 16px !important;

        }
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
    .poper-section {
        width: 150px;

        &__chips {
            font-size: 10px !important;
        }

        &__popper-btn {
            font-weight: 500 !important;
            font-size: 0.775rem !important;
            line-height: 1.5 !important;
            padding: 8px 16px !important;

        }
    }
}