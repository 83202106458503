.main-footer.main-footer-active {
    transform: translateY(0);
}

.main-footer.show {
    display: block;
}

.main-footer.hide {
    display: none;
}

.main-footer-top{
    z-index: 12 !important;
}

.main-footer {
    font-size: .875rem;
    bottom: 0;
    font-size: .9375rem;
    left: 0;
    position: fixed;
    right: 0;
    width: 100%;
    z-index: 2;

    &_tool {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateY(-100%);
    }

    &_tool-1 {
        justify-content: center;
        display: flex;
    }

    &_tool>div> {
        pointer-events: auto;
    }

    &_list-collapse {
        align-items: center;
        background: 0 0;
        border: 0;
        color: #fff;
        display: flex;
        font-size: inherit;
        height: 2.675rem;
        justify-content: center;
        margin: 0;
        padding: 0;
        position: relative;
        text-shadow: 0 1px 1px #000;
        width: 5.125rem;

        svg {
            font-size: 27px;
            color: var(--color-light-blue);
        }
    }

    &_list-collapse:after {
        background-color: rgba(0, 0, 0, .2);
    }

    &_list-collapse:after {
        background-color: rgba(0, 0, 0, .8);
        border-radius: 1.75em 1.75em 0 0;
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform: perspective(5px) rotateX(2deg) scale(1.5);
        transform-origin: bottom;
        z-index: -1;
    }

    &_wrapper-btn-conatiner.footer-active {
        display: block;
        background: linear-gradient(180deg, rgba(0, 0, 0, .2) 0, #000);
        position: relative;
        width: 100%;
        z-index: 1;
    }

    &_panoramas {
        padding: 6px 135px 0px 167px;
        position: relative;
        display: inline-flex;
        overflow-x: auto;
        width: 100%;
    }

    &_panoramas::-webkit-scrollbar {
        height: 6px;
    }

    &_wrapper-btn-conatiner {
        display: none;
    }

    &_btn-env-list {
        margin-right: 12px;
        padding: 32px 66px;
        border-radius: 6px;
    }

    &_btn-env-list.active-btn {
        border: 4px solid var(--color-light-blue);
    }

    .main-footer-active &_list-collapse:after {
        background-color: #0003;
    }

    &_buttons {
        right: 0;
        width: 100%;
        z-index: 3;
        display: flex;
        justify-content: flex-end;
    }

    &_center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__mobile-btn {
        color: var(--color-light-black) !important;
        font-size: var(--font-size-normal) !important;
        min-width: 0px !important;
        padding: 8px 10px !important;
        margin: 2px !important;
    }

    &__book-btn {
        color: var(--color-light-black) !important;
        font-size: var(--font-size-normal) !important;
    }

    &_Btn-disable {
        background-color: var(--color-secondry) !important;
    }

    &_Btn-enable {
        background-color: var(--color-light-blue) !important;
        text-transform: capitalize !important;
    }

    &__MobileIcon {
        height: 18px;
    }

    &__BookIcon {
        height: 23px;
    }

    &__vertical-devider {
        color: var(--color-white);
    }
}

@media only screen and (max-width: 1050px) {
    .main-footer {
        &_list-collapse {
            width: 3.52rem;
            height: 2.1rem;

            svg {
                font-size: 22px;
            }
        }

        &_btn-env-list {
            margin-right: 8px;
            padding: 22px 40px;
        }

        &_panoramas {
            padding: 6px 148px 0px 148px;
        }

        &__mobile-btn {
            font-size: var(--font-size-10) !important;
        }

        &__book-btn {
            font-size: var(--font-size-10) !important;
        }
        &__BookIcon {
            height: 18px;
        }
        &__MobileIcon {
            height: 15px;
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
    .main-footer {
        // &_buttons {
        //     top: 60px;
        //     left: 65%;
        // }
        &__BookIcon {
            height: 18px;
        }
        &__MobileIcon {
            height: 15px;
        }
    }
}

@media screen and (min-width: 1051px) and (max-width: 1200px) {
    .main-footer {
        &_btn-env-list {
            margin-right: 10px;
            padding: 25px 50px;
        }
    }
}