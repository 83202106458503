.tech {
    &__list {
        position: fixed;
        z-index: 2;
        left: 10%;
        bottom: 55%;
    }

    &__tech-wrapper {
        padding: 6px 178px 0px 155px;
        position: fixed;
        display: inline-flex;
        overflow-x: auto;
        width: 100%;
        bottom: 48px;
    }

    &__tech-wrapper::-webkit-scrollbar {
        display: none;
    }

    &_btn {
        margin-left: 10px;
        background-color: var(--color-light-blue);
        color: white;
        font-size: var(--font-size-12);

        &:hover {
            color: white;
        }
    }
}