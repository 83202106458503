.menu-wrapper {
    width: 145px;
    margin: 12px 6px;

    &__category-btn {
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.5;
        padding: 8px 16px;
        border-radius: 8px;
        transition: all 150ms ease;
        background: transparent;
        border: 1px solid #35bbd1;
        color: #fff;
        width: 100%;
        box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

        &:hover {
            background: #35bbd1;
        }
    }

    &__cat-btn-active {
        background: #35bbd1;
        font-weight: 600 !important;
    }

    &__menu {
        font-size: 13px;
        font-weight: 500;
        box-sizing: border-box;
        padding: 6px;
        margin: 6px 0;
        min-width: 145px;
        width: 145px;
        border-radius: 6px;
        overflow: auto;
        outline: 0;
        background: #fff;
        border: 1px solid #DAE2ED;
        color: #1C2025;
        z-index: 1;

    }

    &__menu-item {
        list-style: none;
        padding: 8px;
        border-radius: 8px;
        cursor: pointer;
        user-select: none;
        border-bottom: none;

        &:hover {
            background: #cbeaf0;
            color: #003A75;
        }
    }

    &__active-menuitem {
        background: #cbeaf0;
        color: #003A75;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .menu-wrapper {
        width: 115px;
        margin: 10px 6px;

        &__category-btn {
            font-weight: 500;
            font-size: 0.675rem;
            line-height: 1.5;
            padding: 8px 16px;
            width: 100%;
        }

        &__menu {
            font-size: 10px;
            font-weight: 500;
            padding: 6px;
            margin: 6px 0;
            min-width: 115px;
            width: 115px;
            border-radius: 6px;
        }

        &__menu-item {
            padding: 8px;
        }
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
    .menu-wrapper {
        width: 125px;
        margin: 12px 6px;

        &__category-btn {
            font-weight: 500;
            font-size: 0.775rem;
            padding: 8px 16px;
            width: 100%;
        }

        &__menu {
            font-size: 12px;
            font-weight: 500;
            box-sizing: border-box;
            padding: 6px;
            margin: 6px 0;
            min-width: 125px;
            width: 125px;
        }

        &__menu-item {
            list-style: none;
            padding: 8px;
            border-radius: 8px;
            cursor: pointer;
            user-select: none;
            border-bottom: none;
        }
    }
}