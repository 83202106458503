
.dropdown-menu {
    position: fixed !important;
}
.param-list-section {
    width: 118px;
    cursor: pointer;
    margin: 19px 5px;

    &__divider {
        color: var(--color-white);
        margin: 8px 0;
    }

    &__heading {
        font-size: var(--font-size-sm);
        color: var(--color-white);
    }

    &__text {
        font-size: 14px;
        text-align: center;
        margin: 0px;
    }

    &__card {
        white-space: break-spaces !important;
        &__avail {
            background-color: var(--color-light-blue) !important;
        }

        &__not-avail {
            background-color: var(--color-light-blue) !important;
        }

        &__selected {
            background-color: var(--color-light-blue) !important;
            outline: 3px solid var(--color-light-blue) !important;
            outline-offset: 2px;
        }
    }
    &__dropdown-wrapper {
        min-width: 118px;
    }
}

@media only screen and (max-width: 1050px) {
    .param-list-section {
        width: 100px;

        &__heading {
            font-size: var(--font-size-12);

            svg {
                font-size: var(--font-size-normal);
            }
        }

        &__text {
            font-size: var(--font-size-10);
        }

        &__dropdown-wrapper {
            min-width: 102px;
        }
    }
}