:root {

    --color-primary: #202124;
    --color-secondry: #8f8d96;
    --color-actions: #ffffff;

    --color-whisper: #ececec;
    --color-dakBlue: #20264a;

    --color-black-russian: #202124;
    --color-white: #ffffff;
    --color-black: #232323;
    --color-light-blue: #35bbd1;
    --color-light-black: #333;

    --font-size-sm: 14px;
    --font-size-normal: 16px;
    --font-size-med: 20px;
    --font-size-md: 22px;
    --font-size-medium: 32px;
    --font-size-medium-lg: 36px;
    --font-size-lg: 48px;
    --font-size-large: 64px;
    --font-size-24: 24px;
    --font-size-12: 12px;
    --font-size-18: 18px;
    --font-size-28: 28px;
    --font-size-10: 10px;
    --font-size-40: 40px;
    --font-size-21: 21px;
    --font-size-20: 20px;
    --font-size-17: 17px;
    --font-size-8: 8px;
    --font-size-26: 26px;

    --font-weight-400: 400;
    --font-weight-500: 500;
    --font-weight-600: 600;
    --font-weight-700: 700;
    --font-weight-900: 900;

}