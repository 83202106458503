.model-selector {
  position: relative;
  right: 0;
  z-index: 10;
  height: 93vh;
  max-height: 100vh;
  padding: 3px;
  display: flex;
  justify-content: space-around;
  background-color: var(--color-light-black);

  &__card {
    padding: 20px 20px;
    margin-bottom: 8px;
    background-color: var(--color-secondry);
  }

  &__scroll {
    overflow-y: auto;
  }

  &__scroll::-webkit-scrollbar {
    display: none;
  }
}

.range-slider-menu {
  max-width: 18%;
  padding: 0px;
  position: fixed;
  transform: translate3d(119px, -54px, 0px);
  list-style: none;
  background-color: var(--color-white);
  padding: 10px;
}

.range-slider-menu::before {
  content: "";
  position: absolute;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent;
  left: -19px;
  top: 23px;
  border-right: 10px solid white;
}

.range-slider-menu .range-slider {
  text-align: center;
}

.card-size {
  width: 50px;
}

@media only screen and (max-width: 1050px) {
  .range-slider-menu {
    transform: translate3d(100px, -32px, 0px);

    .card-text {
      font-size: var(--font-size-12);
    }
  }
}