.parameter-selector {
  position: relative;
  left: 0;
  height: 93vh;
  max-height: 100vh;
  display: flex;
  justify-content: space-around;
  background-color: var(--color-light-black);
  z-index: 3;
  padding: 3px;

  &__card {
    padding: 15px 10px;
    margin-bottom: 8px;
    width: 120px;

    &_applicable {
      background-color: var(--color-light-blue);
    }

    &_not-applicable {
      background-color: var(--color-secondry);
    }
  }

  &__scroll {
    overflow-y: auto;
  }

  &__scroll::-webkit-scrollbar {
    display: none;
  }
}

.parameter-selector__card.card-active {
  background-color: var(--color-light-blue);
}

.wrapper-center {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;

  &__text {
    font-size: 14px;
  }

  &_check-icon {
    height: 24px;
    width: 30px;
  }
}

.disabled-check-icon {
  color: light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3));
}

@media only screen and (max-width: 1050px) {
  .wrapper-center {
    &__text {
      font-size: var(--font-size-10);
    }

    &_check-icon {
      height: 20px;
      width: 20px;
    }
  }
}

@media screen and (max-height: 600px) {
  .parameter-selector {
    &__scroll {
      overflow-y: auto;
      max-height: 80vh;
    }
  }
}
@media screen and (min-height: 601px) and (max-height: 800px) {
  .parameter-selector {
    &__scroll {
      overflow-y: auto;
      max-height: 83vh;
    }
  }
}
@media screen and (min-height: 801px) and (max-height: 900px) {
  .parameter-selector {
    &__scroll {
      overflow-y: auto;
      max-height: 87vh;
    }
  }
}