.dropdown {
    &-btn {
        margin-left: 20px !important;

        &-active {
            color: var(--color-light-blue) !important;
            font-size: var(--font-size-17) !important;
        }

        &-not-active {
            color: white !important;
            font-size: var(--font-size-17) !important;
        }
    }

    &-menu-item {
        &:hover {
            background-color: var(--color-light-blue) !important;
        }

        &-active {
            background-color: var(--color-light-blue) !important;
            font-size: var(--font-size-normal) !important;
        }

        &-not-active {
            background-color: white !important;
            font-size: var(--font-size-normal) !important;
        }
    }
}

@media only screen and (max-width: 1050px) {
    .dropdown {
        &-menu-item {
            &-active {
                font-size: var(--font-size-12) !important;
            }

            &-not-active {
                font-size: var(--font-size-12) !important;
            }
        }
    }
}

@media only screen and (max-width: 1050px) {
    .dropdown {
        &-btn {

            &-active {
                font-size: var(--font-size-12) !important;
            }

            &-not-active {
                font-size: var(--font-size-12) !important;
            }
        }
    }
}