.tech-compare-layout {
   width: 100%;

   &__select-dropdown {
      width: auto !important;
      display: flex !important;
   }

   &__color-select-btn {
      margin: 0 5px !important;
      width: 30px !important;
      height: 30px !important;
      min-width: unset !important;
      border-radius: 50% !important;
   }

   &__price-section {
      padding: 10px 0;
   }

   &__heading {
      margin-top: 0.5rem !important;
      font-size: var(--font-size-20) !important;
      font-weight: 500 !important;
   }

   &__price-section-list {
      font-size: var(--font-size-sm) !important;
   }

   &__card {
      padding: 10px !important;
      text-align: left !important;
      height: 95% !important;
      box-shadow: 0px 0px 0px -1px rgba(0, 0, 0, 0.2), 0px 0px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
   }

   &__tittle-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
   }

   &__mobile-book-btn {
      display: flex;
      gap: 6px;
   }

   &__btn {
      background-color: var(--color-light-blue) !important;
      text-transform: capitalize !important;
   }

   &_panoramas {
      padding: 6px 0px 0px 1px;
      position: absolute;
      display: flex;
   }

   &_btn-env-list {
      padding: 13px 22px;
      border-radius: 4px;
      margin-right: 10px;
   }

   &_btn-env-list.active-btn {
      border: 2px solid var(--color-light-blue);
   }

   &_bg-selection-wrapper {
      position: relative;
      overflow-x: scroll;
      height: 50px;
   }

   &_bg-selection-wrapper::-webkit-scrollbar {
      display: none;
   }
}

.tech-compare-layout__canvas canvas {
   position: absolute !important;
}

@media only screen and (max-width: 1050px) {
   .tech-compare-layout {
      &_btn-env-list {
         padding: 10px 20px;
      }
      &__heading {
         font-size: var(--font-size-normal) !important;
         font-weight: 500 !important;
      }
      &__price-section-list {
         font-size: var(--font-size-sm) !important;
      }
      &__price-section {
         padding: 6px 0;
      }
   }
}