.accordion {
    background-color: var(--color-light-black) !important;
    color: var(--color-white) !important;

    &_summary {
        padding: 0 !important;
        min-height: 50px !important;

        div {
            margin: 15px 0 !important;
        }

        p {
            font-size: var(--font-size-18) !important;
        }
    }

    &_details {
        padding: 0px 20px 16px !important;
    }

    &_icon {
        color: var(--color-white) !important;
    }

    &_ul-list {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
        display: grid !important;
        grid-template-columns: 1fr 1fr !important;
        list-style-type: disc !important;
    }

    &_list {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }
}

@media only screen and (max-width: 1050px) {
    .accordion {
        &_discription-label {
            font-size: var(--font-size-12) !important;
        }

        &_discription-content {
            font-size: var(--font-size-12) !important;
        }

        &_icon {
            font-size: var(--font-size-normal) !important;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .accordion {
        &_summary {
            p {
                font-size: var(--font-size-sm) !important;
            }
        }
        &_list {
            font-size: var(--font-size-12);
        }
    }
}