.control-pane {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: var(--color-white);
    width: auto;
    margin-bottom: 8px;
    border-radius: 3px;
  }
  
  #custom-content {
    padding: 5px;
    width: 100%;
  }
  
  .col-3 {
    padding: 5px;
  }
  
  .e-rounded-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 3px;
  }
  
  .e-tile.e-rounded-palette {
    border: .5px solid rgba(0, 0, 0, 0);
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    border-radius: 4px;
    height: 25px;
    width: 30px;
  }
  
  .e-tile.e-rounded-palette:focus {
    border: 2px solid var(--color-white);
    outline: none;
  }
  
  .e-tile.e-rounded-palette[aria-selected="true"] {
    border: 2px solid var(--color-light-blue); 
  }
