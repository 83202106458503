.loader {
  display: inline-block;
  position: relative;
  letter-spacing: 4px;
  box-sizing: border-box;
  text-align: center;
}

.black_theme {
  color: var(--color-black);
  font-size: var(--font-size-20);
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  left: -38px;
  top: -100px;
}

.black_theme::after {
  content: "";
  width: 3px;
  height: 3px;
  background: var(--color-light-blue);
  position: absolute;
  right: 55px;
  top: 34px;
  box-sizing: border-box;
  animation: animloader 0.5s ease-out infinite alternate;
}

.black_theme::before {
  content: "";
  position: absolute;
  right: 55px;
  bottom: 9px;
  height: 10px;
  width: 3px;
  outline: 1px solid var(--color-black);
  color: var(--color-light-blue);
  box-sizing: border-box;
  animation: animloader1 0.5s linear infinite alternate;
}

.white_theme {
  font-size: var(--font-size-40);
  color: var(--color-white);
}

.white_theme::before {
  content: '';
  position: absolute;
  right: 95px;
  bottom: 16px;
  height: 24px;
  width: 7px;
  outline: 1px solid var(--color-white);
  color: var(--color-light-blue);
  box-sizing: border-box;
  animation: animloader1 0.1s linear infinite alternate;
}

.white_theme::after {
  content: '';
  width: 4px;
  height: 4px;
  background: var(--color-light-blue);
  position: absolute;
  right: 97px;
  top: 14px;
  box-sizing: border-box;
  animation: animloader 0.1s ease-out infinite alternate;
}

@keyframes animloader {
  0% {
    top: 8px;
    transform: rotate(0deg) scale(1);
  }

  100% {
    top: 0px;
    transform: rotate(180deg) scale(1.5);
  }
}

@keyframes animloader1 {
  0% {
    box-shadow: 0 0 inset;
  }

  100% {
    box-shadow: 0 -28px inset;
  }
}