.css-1t1j96h-MuiPaper-root-MuiDialog-paper::-webkit-scrollbar {
    display: none;
}

.customized-dialog {
    &__close-icon {
        color: var(--color-white) !important;
        z-index: 3 !important;
    }

    .MuiDialog-paper {
        width: 90% !important;
        height: 90% !important;
        max-width: 100% !important;
        max-height: 100% !important;
        background-color: var(--color-light-black) !important;
        outline: dimgrey solid 5px !important;
    }

    &__dialog-content {
        overflow-y: scroll !important;
    }

    &__dialog-content::-webkit-scrollbar {
        display: none;
    }

    &__dialog-title {
        text-align: center;
        padding: 8px 16px !important;
        color: var(--color-white) !important;
    }
}