.ultima-selected {
    &_tech {
        top: 92px;
        right: 280px;
        position: fixed;
        width: 100%;
        z-index: 1;
        font-size: var(--font-size-26);
        justify-content: end;
        display: flex;
        color: var(--color-actions);
    }

    &_active {
        background: #35bbd1 !important;
        font-weight: 600 !important;
    }

    &_btn {
        border: none;
        border-radius: 8px;
        transition: all 150ms ease;
        background: var(--color-light-black);
        color: #fff;
        font-size: var(--font-size-12);
        line-height: 1.5;
        padding: 10px 15px;
        margin-right: 4px;
    }
}

@media only screen and (max-width: 1050px) {
    .ultima-selected {
        &_tech {
            top: 73px;
            left: 275px;
        }
    }
}