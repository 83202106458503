.office-env {
    position: relative !important;
    top: 0px;
    z-index: 0;
    bottom: -10px;
    // height: auto !important;
}

@media only screen and (max-width: 1050px) {
    .office-env {
        top: 0px;
    }
}