@import "./variables/variables";
@import "../components/LensCategories/lensCategories";
@import "../components/LensModels/lensModels";
@import "../components/LensParameters/lensParameters";
@import "../components/InfoLayout/infoLayout";
@import '../widgets/LensLoader/lens-loader';
@import '../widgets/DropdownMenuItem/dropdownMenuItem';
@import '../widgets/LensParams/lensParams';
@import '../widgets/ColorPicker/colorPicker';
@import '../widgets/Loader/loader';
@import '../widgets/BackgroundSelection/backgroundSelection';
@import '../widgets/Accordion/accordion';
@import '../components/CompareTechnologies/compareTechnologies';
@import '../widgets/MaterialThicknessComparison/MaterialThicknessComparison';
@import '../widgets/RadioButton/RadioButton';
@import '../widgets/Poppers/poppers';
@import '../components/AnimationDialog/AnimationDialog';
@import '../widgets/MenuTransitions/MenuTransitions';
@import '../widgets/Technologies/techs';
@import '../components/UltimaVariations/ultimaVariations';
@import '../components/LandscapeNotification/LandscapeNotification';

html,
body,
#root {
  min-height: 100vh;
  overflow: hidden;
  font-family: 'Poppins', sans-serif !important;
}

.container-main {
  position: relative;
  display: flex;
  justify-content: space-between;
}

input[type="checkbox"]:checked {
  background-color: var(--color-light-blue);
}

.rightPanel-cards {
  background-color: var(--color-secondry);
}

.rightPanel-cards ul {
  padding-left: 0;
}

.wrapper-btn-conatiner {
  position: absolute;
  bottom: 0px;
  left: 50%;
  width: 80vw;
  transform: translateX(-50%);
  border-radius: 5px;
  overflow: hidden;
  z-index: 1;
}

.selected_tech {
  top: 76px;
  left: 184px;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 1;
  font-size: var(--font-size-26);
  justify-content: start;
  display: flex;
  color: var(--color-actions);

  span {
    background: var(--color-light-black);
    display: inline-block;
    color: white;
    padding: 10px 25px;
    border-radius: 15px;
    font-size: 16px;
    border: none;
    outline: none;
    cursor: pointer;
    position: relative;
    clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%, 0% 100%, 10% 0%);
    margin-top: 6px;
    border-radius: 22px 8px;
  }
}

.compare-wrapper {
  overflow-y: scroll;
  height: 90vh;
}

.compare-wrapper::-webkit-scrollbar {
  display: none;
}

.compare-tech-section {
  position: relative !important;
  top: 0px;
  z-index: 0;
  bottom: -10px;
  height: auto !important;
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
  padding: 2%;
}

@media only screen and (max-width: 1050px) {
  .selected_tech {
    top: 60px;
    left: 165px;
    font-size: var(--font-size-med);

    span {
      font-size: 12px;
    }
  }
}