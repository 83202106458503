.running-text-container {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #f44336;
    color: white;
    overflow: hidden;
    padding: 10px 0;
    z-index: 9999;
    font-size: 14px;
    text-align: center;
  }
  
  .running-text {
    display: inline-block;
    white-space: nowrap; 
    animation: moveText 10s linear infinite;
  }
  
  @keyframes moveText {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  