.info-layout-wrapper {
    left: 0;
    right: 0;
    width: 100%;
    z-index: 2;

    &_handle {
        position: relative;
        top: 56px;
        width: auto;
        display: flex;
        justify-content: center;
    }

    &__arrow-wrapper {
        align-items: center;
        display: flex;
        height: 2rem;
        justify-content: center;
        position: relative;
        width: 3.7rem;
        border-radius: 0 0 1.75em 1.75em;
        cursor: pointer;
    }

    &_bounce {
        animation: bounce 2s infinite;
        cursor: pointer;
        height: 30px;
        width: 30px;
        color: var(--color-light-blue);
    }
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}

.info-layout {
    position: relative;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    z-index: 2;

    &_show {

        .info-layout {
            &_handle {
                bottom: unset;
            }
        }
    }

    &_hide {
        height: 20px;
        overflow-y: hidden;

        .info-layout {
            &_row {
                position: absolute;
            }
        }
    }

    &_row {
        background-color: var(--color-light-black);
        border-radius: 5px;
        bottom: 18px;
    }

    &_animation-area {
        display: flex;
        align-items: center;
        justify-content: center;

        video {
            width: 100%;
        }
    }

    &-heading {
        text-align: center;
        font-size: var(--font-size-24);
        font-weight: 500;
        color: var(--color-white);
        padding: 6px 0;
    }

    &__compare-enable {
        display: flex;
        width: 100%;
    }

    &__compare-layout {
        width: 50%;
        height: fit-content;
    }

    &__compare-btn {
        background-color: var(--color-light-blue) !important;
        color: var(--color-light-black) !important;
        margin-left: 4px !important;
        font-size: var(--font-size-12) !important;
    }

    &__full-video-layout {
        padding: 0 10px;
    }

    &__half-video-layout {
        padding: 0px;
    }
}

@media only screen and (max-width: 1050px) {
    .info-layout {
        &__compare-btn {
            font-size: var(--font-size-10) !important;
            padding: 4px 8px !important;
        }
    }

    .info-layout-wrapper {
        &_handle {
            top: 34px;
        }

        &_bounce {
            height: 20px;
            width: 20px;
        }

        &__arrow-wrapper {
            width: 3.125rem;
            height: 2rem;
        }
    }
}
@media only screen and (max-width: 1200px) {
    .info-layout {
        &-heading {
            font-size: var(--font-size-normal);
            font-weight: 400;
        }
    }
}